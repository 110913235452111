const topOffset = 18;
const defaultLocale = "et";

export { topOffset, defaultLocale };

export enum ViewLinks {
  AddPerson = "/settings/apartment/add-person",
  Association = "/association",
  Calendar = "/calendar",
  Entrances = "/entrances",
  Invoices = "/invoices",
  MessageComposer = "/composer",
  Messages = "/messages",
  Readings = "/readings",
  Settings = "/settings",
  Transactions = "/transactions",
  UpdatePassword = "/settings/user/update-password"
}

export enum ViewNames {
  Messages = "Messages",
  Readings = "Readings",
  Invoices = "Invoices",
  Association = "Association",
  Settings = "Settings",
  AddPerson = "AddPerson",
  UpdatePassword = "UpdatePassword",
  Entrances = "Entrances",
  MessageComposer = "MessageComposer"
}

export const ViewLinksWithPrivatePrefix: Record<keyof typeof ViewLinks, string> = Object.fromEntries(
  Object.entries(ViewLinks).map(([key, value]) => [key, `/private${value}`])
) as Record<keyof typeof ViewLinks, string>;
